<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Name<span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
            <v-text-field
              flat
              dense
              outlined
              background-color="white"
              v-model="form.name"
              :rules="[v => !!v || 'Name is required']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs vertical>
          <v-tab background-color="light-blue accent-3" dark class="px-0">
            <v-icon>
              mdi-note-text
            </v-icon>
          </v-tab>
          <v-tab-item>
            <v-card flat class="px-2">
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    outlined
                    flat
                    dense
                    background-color="white"
                    append-icon="mdi-magnify"
                    :items="listCategory"
                    item-text="category"
                    return-object
                    v-model="category"
                    @change="onChangeCategory"
                  >
                    <template v-slot:item="data">
                      <v-list-item v-on="data.on">
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.category"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" class="text-right pb-0">
                  <p class="headline font-italic">Requirement <span class="red--text">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="form.items"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:item.no="{ index }">
                      {{ index + 1 }}
                    </template>
                    <template v-slot:item.amount="{ item }">
                      <v-text-field
                        flat
                        dense
                        outlined
                        background-color="white"
                        v-model.number="item.amount"
                        type="number"
                        :rules="[v => !!v || 'Name is required']"
                        persistent-hint
                        :hint="formatPrice(item.amount)"
                      ></v-text-field>
                    </template>
                    <template v-slot:item.action="{ index }">
                      <v-btn color="error" @click="deleteItem(index)"
                        ><v-icon>mdi-trash-can</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "form-kasbon-template",
  props: {
    form: Object,
    listCategory: Array,
  },
  data: () => ({
    category: null,
    valid: true,
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Category",
        value: "categoryName",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
      },
    ],
  }),

  methods: {
    async submit() {
      if (this.$refs.form.validate() && this.form.items.length > 0) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("generalKasbon/createKasbonTemplate", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Requirement is required",
        });
      }
    },
    deleteItem(index) {
      if (index > -1) {
        this.form.items.splice(index, 1);
        this.category = null;
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onChangeCategory(val) {
      this.form.items.push({ ...val });
      this.category = null;
    },
  },
};
</script>

<style></style>
