<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Name<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ form.name }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs vertical>
            <v-tab background-color="light-blue accent-3" dark class="px-0">
              <v-icon>
                mdi-note-text
              </v-icon>
            </v-tab>
            <v-tab-item>
              <v-card flat class="px-2">
                <v-row>
                  <v-col cols="12" class="text-right pb-0">
                    <p class="headline font-italic">Requirement <span class="red--text">*</span></p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers"
                      :items="form.items"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:item.no="{ index }">
                        {{ index + 1 }}
                      </template>
                      <template v-slot:item.amount="{ item }">
                        {{ formatPrice(item.amount) }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "detail-kasbon-template",
  props: {
    form: Object,
  },
  data: () => ({
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Category",
        value: "categoryName",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
